import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This cheatsheet will have more code snippets on how to do something in bash. It will be a bit different from some other cheatsheets where I show a list of commands.`}</p>
    <h2 {...{
      "id": "basics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basics",
        "aria-label": "basics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basics`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`echo`}</inlineCode>{` - print text to the terminal`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`echo Hello world!`}</inlineCode>{` - prints 'Hello world!'`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#variables",
        "aria-label": "variables permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Variables`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`COLOR="green"`}</inlineCode>{` - create variable `}<inlineCode parentName="li">{`COLOR`}</inlineCode>{` and set it to green, you can then use it such as:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`echo $COLOR`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`echo "$COLOR"`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`echo "\${COLOR}"`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`echo "My favourite color is $COLOR!"`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <Note mdxType="Note">
  You need to use double quotes if you want to print text and variable. Using{" "}
  <code>echo 'my favourite color is $COLOR'</code> would print{" "}
  <code>my favourite color is $COLOR </code> to the terminal
    </Note>
    <h3 {...{
      "id": "arrays",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#arrays",
        "aria-label": "arrays permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Arrays`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`COLORS=("red", "green", "blue")`}</inlineCode>{` - Define an array of colors`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`echo \${COLORS[0]}`}</inlineCode>{` - Print first element in the array`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`echo \${COLORS[-1]}`}</inlineCode>{` - Print last element in the array`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`echo \${COLORS[@]}`}</inlineCode>{` - Prints all elements separated by a space`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`echo \${#COLORS[@]}`}</inlineCode>{` - Prints the number of elements in the array`}</li>
    </ul>
    <h3 {...{
      "id": "loops",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#loops",
        "aria-label": "loops permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loops`}</h3>
    <p>{`For loops:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`for item in \${MYLIST[@]}; do
  echo $item
done
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`for ((i=0; i < 10; i++)); do
  echo $i
done
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`for i in {1..10}; do
  echo "Iteration number: $i"
done
`}</code></pre>
    <p>{`While loop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`while true; do
  echo "HELLO!"
done
`}</code></pre>
    <h3 {...{
      "id": "conditionals",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#conditionals",
        "aria-label": "conditionals permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conditionals`}</h3>
    <p>{`Conditionals follow the familiar if statements:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`if [ $COLOR != "green" ]; then
  echo "Your favourite color isn't green"
fi
`}</code></pre>
    <p>{`You can use `}<inlineCode parentName="p">{`[[ ... ]]`}</inlineCode>{` to get a status of 0 or 1 depending on the evaluation of the conditional expression inside `}<inlineCode parentName="p">{`[[]]`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -z STRING ]]`}</inlineCode>{` - Empty string`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -n STRING ]]`}</inlineCode>{` - Not empty string`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -e FILE ]]`}</inlineCode>{` - File exists`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -r FILE ]]`}</inlineCode>{` - File is readable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -d PATH ]]`}</inlineCode>{` - PATH is a directory`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -w FILE ]]`}</inlineCode>{` - File is writeable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -s FILE ]]`}</inlineCode>{` - File size is larger than 0 bytes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ -x FILE ]]`}</inlineCode>{` - File is executable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ STRING == STRING ]]`}</inlineCode>{` - Both strings are equal`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ STRING != STRING ]]`}</inlineCode>{` - Strings aren't equal`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ STRING =~ STRING ]]`}</inlineCode>{` - Test STRING against a regex pattern`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -eq NUM]]`}</inlineCode>{` - Both numbers are equal`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -ne NUM ]]`}</inlineCode>{` - Numbers aren't equal`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -lt NUM ]]`}</inlineCode>{` - First NUM is less than second NUM`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -le NUM ]]`}</inlineCode>{` - First NUM is less or equal than second NUM`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -gt NUM ]]`}</inlineCode>{` - First NUM is greater than second NUM`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ NUM -ge NUM ]]`}</inlineCode>{` - First NUM is greater or equal than second NUM`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ ! EXPRESSION ]]`}</inlineCode>{` - Not`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ EXPRESSION && EXPRESSION ]]`}</inlineCode>{` - And`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[[ EXPRESSION || EXPRESSION ]]`}</inlineCode>{` - Or`}</li>
    </ul>
    <h2 {...{
      "id": "references",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#references",
        "aria-label": "references permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gnu.org/savannah-checkouts/gnu/bash/manual/bash.html"
        }}>{`Bash Reference Manual`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://devhints.io/bash#conditionals"
        }}>{`Devhints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learnxinyminutes.com/docs/bash/"
        }}>{`LearnXinYMinutes - Bash`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      